import {useEffect, useState} from 'react';
import {displayDate} from "../../utils/dateUtils";
import {TextField, Typography, FormControlLabel, Checkbox, MenuItem} from "@mui/material";
import MaterialButton from "../MaterialButton";
import {storageType, useStorage} from "../../store";
import {submitCaseWithdraw, useWithdrawalReasons} from "../../api/casesApi";
import Select from "../Select";
import {genericMapper} from "../../utils/stringUtils";

const defaultFormValues = {
    reasonCode: '',
    reason: '',
}

const WithdrawalTab = ({caseObject, onError, onUpdate}) => {
    const [operator] = useStorage(storageType.SESSION, "operator");
    const {reasons : operatorReasons, loading: loadingOperatorReasons} = useWithdrawalReasons('OPERATOR', sessionStorage.getItem('token'))
    const {reasons : appellantReasons, loading: loadingAppellantReasons} = useWithdrawalReasons('APPELLANT', sessionStorage.getItem('token'))
    const [selectedReasons, setSelectedReasons] = useState(operatorReasons);
    const [formValues, setFormValues] = useState(defaultFormValues)
    const [isLoading, setIsLoading] = useState(false)
    const [reasonCodeError, setReasonCodeError] = useState(null)
    const [notesError, setNotesError] = useState(null)
    const [withdrawForAppellant, setWithdrawForAppellant] = useState(false)

    const notesMaxLength = 2000;
    const canSubmit =  checkCanSubmit();

    useEffect(() => {
        if (operatorReasons) {
            setSelectedReasons(operatorReasons)
        }
    }, [operatorReasons]);

    function  checkCanSubmit() {
        if ((formValues.reasonCode === 'OTHER') && (formValues.reason.length > 0)) {
            return true;
        } else if ((formValues.reasonCode !== 'OTHER' && !!formValues.reasonCode) || !!formValues.reason) {
            return true;
        }
        return false;
    }

    function handleInput(e) {
        const {name, value} = e.target
        if (value != null) {
            setFormValues({...formValues, [name]: value})
        }
    }

    function handleWithdrawOnBehalfOf(e) {
        if (e.target.checked) {
            setSelectedReasons(appellantReasons)
            setWithdrawForAppellant(true)
        } else {
            setSelectedReasons(operatorReasons)
            setWithdrawForAppellant(false)
        }
    }

    function validate() {
        const {reasonCode, reason} = formValues
        setReasonCodeError('')
        setNotesError('')

        if (reasonCode.length === 0 && reason.length === 0) {
                setReasonCodeError('This field is required')
                return false
        }

        if (reasonCode === 'OTHER' && reason.length === 0) {
            setNotesError('This field is required')
            return false
        }


        if (reason.length > notesMaxLength) {
            setNotesError('Maximum 2000 characters')
            return false
        }

        if (reason.match(/[<>]+/g)) {
            setNotesError('Must not contain <> characters')
            return false
        }

        setNotesError(null)
        return true;
    }

    function handleSubmit() {
        if (validate()) {
            setIsLoading(true)
            submitCaseWithdraw(
                operator.operatorCode,
                sessionStorage.getItem('token'),
                caseObject.caseId,
                formValues.reason,
                withdrawForAppellant,
                formValues.reasonCode
            ).then(error => {
                if (error.errorMessage != null) {
                    onError(error.errorMessage)
                } else {
                    onUpdate('Case successfully withdrawn')
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    if (caseObject?.condition === "WITHDRAWN") {
        return (
            <>
                <p><strong>Withdrawn By: </strong>{caseObject?.withdrawnBy}</p>
                <p><strong>Withdrawal Reason: </strong>{caseObject?.withdrawalReasons}</p>
                <p><strong>Withdrawal Date: </strong>{displayDate(caseObject?.withdrawalDate)}</p>
            </>
        )
    }

    return (
        <>
            <Typography variant="subtitle1" style={{fontWeight: 'bold', marginTop: 16, marginBottom: 10}}>
                Withdraw your appeal
            </Typography>

            <Select
                    label="Reason Type"
                    name="reasonCode"
                    dataTestId="withdrawReason-select"
                    value={formValues.reasonCode.length ? formValues.reasonCode : ''}
                    onChange={handleInput}
                    required={true}
                    disabled={loadingOperatorReasons || loadingAppellantReasons}
                    helperText={reasonCodeError}
                    error={!!reasonCodeError}
                >
                    {selectedReasons.map(selectedReason => (
                        <MenuItem
                            key={selectedReason.reasonCode}
                            value={selectedReason.reasonCode}
                        >{genericMapper(selectedReason.description)}</MenuItem>
                    ))}
                </Select>

            <TextField
                label="Reason"
                multiline
                fullWidth
                variant="filled"
                minRows={5}
                required={formValues.reasonCode === ''}
                maxLength={notesMaxLength}
                onInput={handleInput}
                onChange={handleInput}
                helperText={notesError}
                error={!!notesError}
                name="reason"
                value={formValues.reason}
                style={{marginBottom: 16}}
                data-testid="reason-textfield"
            />
            <FormControlLabel
                data-testid={"withdraw-for-appellant"}
                checked={withdrawForAppellant}
                value="confirm"
                control={<Checkbox name="case-withdraw-for-appellant" color="primary"/>}
                label="Withdraw on behalf of appellant"
                labelPlacement="end"
                onChange={(e) => handleWithdrawOnBehalfOf(e)}
            />
            <MaterialButton
                disabled={isLoading || !canSubmit}
                name="Withdraw"
                onClick={handleSubmit}
                spinner={isLoading}
                dataTestId="withdraw-button"
            />
        </>
    )
}

export default WithdrawalTab