import React from 'react';
import Login from "./components/Login";
import Cases from "./components/Cases";
import ErrorPage from "./components/ErrorPage";
import {Route, Redirect, Switch} from "react-router-dom";
import CaseView from "./components/caseview/CaseView";
import EvidenceView from "./components/caseview/EvidenceView";
import {OperatorSelectionPage} from "./components/operators/OperatorSelectionPage";
import MenuAppBar from "./components/MenuAppBar";
import {UsersView} from "./components/users/UsersView";
import {AddEditUserView} from "./components/users/AddEditUserView";
import ResetPasswordPage from "./components/ResetPasswordPage";
import PasswordResetSuccessPage from "./components/PasswordResetSuccessPage";
import UpdatePasswordPage from "./components/UpdatePasswordPage";
import PasswordUpdateSuccessPage from "./components/PasswordUpdateSuccessPage";
import {staticPaths} from "./utils/staticPaths";
import {clearSession} from "./api/loginApi";
import AuthVerify from "./components/AuthVerify";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        const token = sessionStorage.getItem('token');
        const operatorJSON = sessionStorage.getItem('operator');
        const operator = operatorJSON ? JSON.parse(operatorJSON) : null;
        this.state = {
            isLoggedIn: !!token,
            operator: operator,
            errorMessage: ''
        }
    }

    doRedirectToPortal = (doRedirect) => {
        this.setState({
            isLoggedIn: doRedirect
        });

        if (!doRedirect) {
            this.setState({
                operator: null
            });
        }
    }

    setSelectedOperator = (operator) => {
        sessionStorage.setItem('operator', operator);
        this.setState({
            operator: operator
        });
    }

    handleLogout = () => {
        clearSession()
        this.doRedirectToPortal(false);
    }

    renderRedirect = () => {
        let location = window.location.pathname;
        if (!staticPaths.includes(location)) {
            if (!this.state.isLoggedIn) {
                return <Redirect to='/'/>
            }
            if (!this.state.operator) {
                return <Redirect to='/account'/>
            }
            if (location === '/') {
                return <Redirect to='/portal'/>
            }
        }
    }

    render() {
        return (
            <div className="App">
                {this.renderRedirect()}
                {this.state.isLoggedIn && <MenuAppBar name={'Popla'} onLogOut={this.handleLogout.bind(this)} />}
                <Switch>
                    <Route exact path="/">
                        <Login doRedirectToPortal={this.doRedirectToPortal}/>
                    </Route>
                    <Route exact path="/account">
                        <OperatorSelectionPage dataTestId="select_operator_page"
                                               setSelectedOperator={this.setSelectedOperator} />
                    </Route>
                    <Route exact path="/portal">
                        <Cases
                            doRedirectToPortal={this.doRedirectToPortal}
                            dataTestId="popla_landing_page"
                            operator={this.state.operator}
                        />
                    </Route>
                    <Route exact path="/error">
                        <ErrorPage errorMessage={this.state.errorMessage}/>
                    </Route>
                    <Route exact path="/case/:caseId" render={(props) =>
                        <CaseView {...props} caseId={props.match.params.caseId} dataTestId="operator_case_view" />} />
                    <Route exact path="/case/:caseId/evidence" render={(props) =>
                        <EvidenceView {...props} caseId={props.match.params.caseId}
                                      dataTestId="evidence_view" />} />
                    <Route exact path="/users">
                        <UsersView dataTestId="users_view" />
                    </Route>
                    <Route exact path="/users/new">
                        <AddEditUserView dataTestId="add_user_view" />
                    </Route>
                    <Route exact path="/users/edit">
                        <AddEditUserView dataTestId="edit_user_view" />
                    </Route>
                    <Route exact path="/password/reset">
                        <ResetPasswordPage dataTestId="reset_password_view" />
                    </Route>
                    <Route exact path="/password/reset/success">
                        <PasswordResetSuccessPage dataTestId="reset_password_success_view" />
                    </Route>
                    <Route exact path="/password/update">
                        <UpdatePasswordPage  dataTestId="update_password_view" />
                    </Route>
                    <Route exact path="/password/update/success">
                        <PasswordUpdateSuccessPage dataTestId="update_password_success_view" />
                    </Route>
                </Switch>
                <AuthVerify logout={this.handleLogout.bind(this)} />
            </div>
        );
    }
}
