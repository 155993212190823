import React, {Component} from 'react';
import Input from "./Input";
import MaterialButton from "./MaterialButton";
import {login} from "../api/loginApi";
import {Paper, Typography, Box} from "@mui/material";
import DomPurify from 'dompurify';
import {Link} from "react-router-dom";
import withSnackbar from "./WithSnackbar";

class Login extends Component {
    constructor(props) {
        super(props);
        this.showToast = props.showToast;

        this.state = {
            username: '',
            password: '',
            loading: false,
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trim()
        })
    }

    handleKeyDown = (event) => {
        if (event.keyCode === 13) { //Enter key keycode
            this.submitClick(event);
        }
    };

    submitClick = async (event) => {
        event.preventDefault();

        this.setState({loading: true});

        if(this.state.username.length === 0 || this.state.password.length === 0) {
            this.setState({loading: false});
            return;
        }

        try {
            const token = await login(this.state.username, this.state.password);
            this.setState({loading: false});
            if (token) {
                this.props.doRedirectToPortal(true);
            }
        } catch (error) {
            this.setState({loading: false});
            if (error.response?.data?.message) {
                this.showToast(error.response.data.message, 'error');
            } else {
                this.showToast(error.message, 'error')
            }
        }
    }

    render() {
        return <div data-testid="login_form">
            <Paper elevation={3} className="App-Login-Form">
                <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}} />
                <div className={"App-Login-SubForm"}>
                    <Typography variant="h5" className={"App-Login-Header"}>
                        <b>Log In</b>
                    </Typography>
                    <Input dataTestId={"input_username"} label={"Email"} name={"username"} id={"username"} type={"text"} onChange={this.handleInputChange} />
                    <Input dataTestId={"input_password"} label={"Password"} name={"password"} id={"password"} type={"password"} onChange={this.handleInputChange} onKeyDown={this.handleKeyDown.bind(this)} />
                    <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center", flexDirection: "row-reverse"}}>
                        <MaterialButton
                            dataTestId={"button_submit"}
                            name={"Log in"}
                            onClick={this.submitClick.bind(this)}
                            spinner={this.state.loading}
                            disabled={this.state.loading}
                        />
                        <Link to='/password/reset' className="App-Form-Link">Forgot password?</Link>
                    </Box>
                </div>
            </Paper>
        </div>
    }
}

export default withSnackbar(Login);