import {useEffect, useState} from "react";
import {apiUrl, axios} from "./apiWrapper";

export const getOperators = async (token) => {
    return await axios.get(`${apiUrl}/operators`,
        {headers: {'Authorization': `Bearer ${token}`}}
    )
}

export const useOperators = (token) => {
    const [isLoading, setIsLoading] = useState(true);
    const [operators, setOperators] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        getOperators(token)
            .then(result => {
                mounted && setOperators(result ? result.data : []);
            })
            .catch(error => {
                mounted && setError(error.message);
            })
            .finally(() => {
                mounted && setIsLoading(false);
            })
        return () => mounted = false;
    }, [token]);

    return {
        isLoading,
        error,
        operators,
    };
}
