import React from "react";
import {Paper} from "@mui/material";
import DomPurify from "dompurify";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function PasswordUpdateSuccessPage() {
    return <div data-testid="login_form">
        <React.Fragment>
            <Paper elevation={3} className="App-Password-Reset-Form">
                <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}} />
                <div className={"App-Password-Update-SubForm"}>
                    <Typography className={"App-Password-Reset-Success-Header"}>
                        <strong>Password update successful. Please try logging in!</strong>
                    </Typography>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Button component={Link} to={'/'}
                                style={{
                                    textTransform: 'none',
                                    color: '#7BAFDF',
                                    fontWeight: "bolder",
                                    fontSize:"medium"
                                }}>Take me to login</Button>
                </div>
                    </div>
            </Paper>
        </React.Fragment>
    </div>
}

export default PasswordUpdateSuccessPage;