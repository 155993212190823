import PropTypes from "prop-types";
import FileAttachmentFiles from "./FileAttachmentFiles";

const FileAttachments = ({files, setFiles}) => {

    function removeFile(index) {
        const updatedFiles = files.filter((f, i) => i !== index)
        setFiles(updatedFiles)
    }

    return (
        <div>
                <FileAttachmentFiles files={files} onRemove={removeFile} />
        </div>
    )
}

FileAttachments.propTypes = {
    onFilesChanged: PropTypes.func,
    disabled: PropTypes.bool
}

export default FileAttachments