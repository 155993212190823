
function tabProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function tabPanelProps(index) {
    return {
        id: `vertical-tabpanel-${index}`,
        'aria-labelledby': `vertical-tab-${index}`,
    };
}

export {
    tabProps,
    tabPanelProps
};