import React, {useEffect} from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useOperators} from "../../api/operatorsApi";

const columns = [
    {field: 'operatorCode', headerName: 'Account Code', width: 200},
    {field: 'name', headerName: 'Name', flex: 1},
];

export const OperatorSelector = ({dataTestId, setLoaded, setError, setOperator}) => {
    const {isLoading, error, operators} = useOperators(sessionStorage.getItem('token'));

    const handleRowClick = (row) => {
        const operatorCode = row.id
        const name = operators.filter(o => o.operatorCode === operatorCode).pop().name;
        const operator = {
            operatorCode,
            name,
        };
        setOperator(operator);
    };

    useEffect(() => {
        if (error) {
            setError(error);
        }
        if (operators) {
            setLoaded(true);
            if (operators.length === 1) {
                setOperator({operatorCode: operators[0].operatorCode, name: operators[0].name});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operators, error]);

    return (
        <div data-testid={dataTestId}>
            {!error &&
                <DataGridPro
                    id={dataTestId + '_data_grid'}
                    rows={operators || []}
                    columns={columns}
                    loading={isLoading}
                    getRowId={row => row.operatorCode}
                    rowCount={operators ? operators.length : 0}
                    onRowClick={handleRowClick}
                    autoHeight
                    disableSelectionOnClick
                />
            }
        </div>
    );
}
