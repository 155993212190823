import Container from "@mui/material/Container";
import PropTypes from 'prop-types';
import { tabPanelProps } from "../../utils/tabbingProps";

const TabPanel = function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...tabPanelProps(index) }
            {...other}
        >
            {value === index && (
                <Container>

                        {children}

                </Container>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;