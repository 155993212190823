import axios from 'axios';
import {clearSession} from "./loginApi";

export const apiUrl = (process.env.REACT_APP_API_URL === undefined) ? '' : process.env.REACT_APP_API_URL;

// For instances where the front end can not communicate with the backend server
// it will redirect to the login page
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!isLoginAttempt(error?.config?.url) && error.response?.status === 401) {
            clearSession();
            return window.location.replace('/login');
        }
        return Promise.reject(error);
    }
);

function isLoginAttempt(url) {
    return url?.includes("/security/authenticate") || false
}

export { axios }