import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Breadcrumbs, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {OperatorSelector} from "./OperatorSelector";
import {storageType, useStorage} from "../../store";

export const OperatorSelectionPage = ({dataTestId, setSelectedOperator}) => {
    const [, setOperatorInSession] = useStorage(storageType.SESSION, "operator");
    const [hasLoaded, setHasLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory();

    const setOperator = (operator) => {
        setSelectedOperator(operator);
        setOperatorInSession(operator);
        history.push("/portal");
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Home</Typography>
                <Typography color="textPrimary">Choose Account</Typography>
            </Breadcrumbs>
            <div data-testid={dataTestId}>
                <h1>Choose Account</h1>
                {errorMessage
                    ?
                    <p data-testid={'operator_selection_error'}>
                        {errorMessage}
                    </p>
                    :
                    <>
                        {hasLoaded
                            ?
                            <p data-testid={'operator_selection_multi_records_info'}>
                                Our records show that you have more than one account with us. Please select the account
                                you wish to access from the list below.
                            </p>
                            :
                            <p>
                                Loading accounts...
                            </p>
                        }
                        <Paper elevation={3}>
                            <OperatorSelector
                                dataTestId="operator_selector"
                                setOperator={setOperator}
                                setLoaded={setHasLoaded}
                                setError={setErrorMessage}
                            />
                        </Paper>
                    </>
                }
            </div>
        </div>
    )
}
