import {useEffect, useState} from "react";
import {apiUrl, axios} from "./apiWrapper";
import {getType} from "mime";

export const useComments = (operatorCode, caseId, token) => {
    const [isLoading, setIsLoading] = useState(true);
    const [evidence, setEvidence] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getEvidence = async () => {
            return await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/comments`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(`Failed to load comments: ${error.message}`);
                return null;
            });
        }
        getEvidence().then(result => {
            if (mounted) {
                const operatorComment = result?.data.find(function (comment) {
                    return comment.commentType === "OPERATOR_COMMENT"
                })

                if (operatorComment == null) {
                    setEvidence({caseId: caseId, comment: "", commentType: "OPERATOR_COMMENT", userType: "OPERATOR"})
                } else {
                    setEvidence(operatorComment);
                }

                setIsLoading(false);
            }
        });
        return () => mounted = false;
    }, [operatorCode, caseId, token]);

    return {
        isLoading,
        error,
        evidence,
    };
};


const attachmentBuilder = (evidenceData) => {
    return ({
        "filename": evidenceData.name,
        "mimeType": getType(evidenceData.name),
        "fileSize": evidenceData.size,
        "userId": "1",
        "uploadStatus": "AWAITING_FILE",
    });
}

const updateAttachmentBuilder = (attachment) => {
    return ({
        ...attachment.data,
        "uploadStatus": "UPLOADED",
    });
}

const evidenceBuilder = (attachment, caseVerificationCode) => {
    return ({
        "caseVerificationCode": caseVerificationCode,
        "evidenceOriginator": "OPERATOR",
        "malwareStatus": "UNCHECKED",
        "name": attachment['filename'],
        "attachmentGuid": attachment['guid'],
        "category": "OP_CASE_SUMMARY"
    });
}


export const postEvidence = async (operatorCode, caseId, comment, token, attachmentList, caseVerificationCode) => {
    let errorMessage = null

    try {
        // loop through files
        for (let i = 0; i < attachmentList.length; i++) {
            // -- call post attachment and receive a signed url to put the file to --
            let attachmentResponse = await axios.post(`${apiUrl}/attachments/`,
                attachmentBuilder(attachmentList[i]), {headers: {'Authorization': `Bearer ${token}`}})
                .catch((err) => {
                    throw new Error(`Failed to create attachment for '${attachmentList[i].name}' (${err.response?.status || err})`)
                })

            // -- upload the file to Amazon --
            if (attachmentResponse != null) {
                await axios.put(attachmentResponse.data['signedUrl'],
                    attachmentList[i], {headers: {'Content-Type': attachmentResponse?.data.mimeType}})
                    .catch((err) => {
                        throw new Error(`Failed to upload '${attachmentList[i].name}' to S3 (${err.response?.status || err})`)
                    })

                // -- when that is complete call put attachment and update the state to uploaded --
                //  return the attachment guid
                attachmentResponse = await axios.put(`${apiUrl}/attachments/`,
                    updateAttachmentBuilder(attachmentResponse), {headers: {'Authorization': `Bearer ${token}`}})
                    .catch((err) => {
                        throw new Error(`Failed to update attachment for '${attachmentList[i].name}' (${err.response?.status || err})`)
                    })

                // then put evidence with the "attachment guid" & "case verification code"
                await axios.put(`${apiUrl}/evidence/operators/${operatorCode}/cases/${caseVerificationCode}`,
                    evidenceBuilder(attachmentResponse.data, caseVerificationCode), {headers: {'Authorization': `Bearer ${token}`}})
                    .catch((err) => {
                        throw new Error(`Failed to update evidence for '${attachmentList[i].name}' (${err.response?.status || err})`)
                    })
            }
        }
    } catch (error) {
        errorMessage = error.message;
        return errorMessage;
    }

    // post the comment and link it to the operator code and case id
    await axios.post(
        `${apiUrl}/operators/${operatorCode}/cases/${caseId}/comments`,
        comment, {headers: {'Authorization': `Bearer ${token}`}}
    ).catch(error => {
        errorMessage = `Failed to create comment for case ${caseId}: ${error.message}`
    });

    return errorMessage
};

export const useEvidenceForCase = (operatorCode, caseId, token) => {

    const [isLoading, setIsLoading] = useState(true);
    const [evidenceForCase, setEvidenceForCase] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;

        const getCase = async () => {
            return await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(error.message)
            });
        }

        const getEvidence = async (verificationCode) => {
            return await axios.get(`${apiUrl}/evidence/operators/${operatorCode}/cases/${verificationCode}`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(error.message);
            });
        }

        const getAttachmentForEvidence = async (attachmentGuid) => {
            return await axios.get(`${apiUrl}/attachments/${attachmentGuid}`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(error.message)
            });
        }

        getCase().then(caseObject => {
            if (mounted) {
                if (caseObject != null) {
                    getEvidence(caseObject.data.verificationCode).then(evidenceArray => {
                        if (evidenceArray != null) {
                            let promises = [];
                            for (let i = 0; i < evidenceArray.data.length; i++) {
                                promises.push(getAttachmentForEvidence(evidenceArray.data[i].attachmentGuid).then(attachmentResponse => {
                                    if (attachmentResponse != null) {
                                        evidenceForCase.push({
                                            ...attachmentResponse.data,
                                            category: evidenceArray.data[i].category,
                                        });
                                    }
                                }));
                            }

                            Promise.all(promises).then(function () {
                                setEvidenceForCase(evidenceForCase)
                                setIsLoading(false);
                            });
                        }
                    });
                }
            }
        });

        return () => mounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operatorCode, caseId, token]);

    return {
        isLoading,
        error,
        evidenceForCase,
    };

}


