import React from "react";
import {InputAdornment, Paper, TextField, Box, IconButton, Typography} from "@mui/material";
import DomPurify from "dompurify";
import MaterialButton from "./MaterialButton";
import {Link, withRouter} from "react-router-dom";
import {updatePassword} from "../api/credentialApi";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import withSnackbar from "./WithSnackbar";

class UpdatePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {},
            errors: {},
            token: '',
            showPassword:false,
            loading:false,
        }
    }

    submitClick = async (event) => {
        event.preventDefault();
        this.setState({loading: true});
        let params = new URLSearchParams(this.props.location.search)

        if (this.validate()) {
            try {
                await updatePassword(this.state.input["password"], params.get('token'));
                this.setState({loading: false});
                this.props.history.push("/password/update/success");
            } catch (error) {
                this.setState({loading: false});
                this.props.showToast("Unable to update password at this time", 'error')
            }
        }

        this.setState({loading: false});
    }

    handleToast = () => {
        this.setState({toast: !this.state.toast});
    };

    handleInputChange = (event) => {
        this.setState({
            errors:{}
        })
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });
    }

    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (typeof input["password"] !== "undefined") {
            let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=])(?=\S+$).{8,}$/);
            if (!pattern.test(input["password"])) {
                isValid = false;
                errors["password"] = "Password should be of at least 8 characters with at least 1 uppercase letter, " +
                    "1 lowercase letter, 1 digit and 1 special character!";
            }
        }

        if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
        }

        if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] !== input["confirm_password"]) {
                isValid = false;
                errors["confirm_password"] = "Passwords don't match.";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleKeyDown = (event) => {
        if (event.keyCode === 13) { //Enter key keycode
            this.submitClick(event);
        }
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render() {
        return (
            <div data-testid="update_form">
                <React.Fragment>
                    <Paper elevation={3} className="App-Password-Reset-Form">
                        <div
                            dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}}/>
                        <div className={"App-Password-Reset-SubForm"}>
                            <Typography variant="h5" className={"App-Login-Header"}>
                                <b>Update Password</b>
                            </Typography>
                            <div className={'App-Input-TextBox'}>
                                <TextField
                                    data-testid={"input_password"}
                                    label={"New Password"}
                                    name={"password"}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.handleInputChange}
                                    variant="filled"
                                    fullWidth
                                    error={this.state.errors?.password !== undefined}
                                    helperText={this.state.errors?.password}
                                    onKeyDown={this.handleKeyDown}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        shrink: 'true',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleClickShowPassword}
                                                    size="large">
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <div className={'App-Input-TextBox'}>
                                <TextField
                                    data-testid={"input_confirm_password"}
                                    label={"Confirm Password"}
                                    name={"confirm_password"}
                                    type={"password"}
                                    onChange={this.handleInputChange}
                                    variant="filled"
                                    fullWidth
                                    error={this.state.errors?.confirm_password !== undefined}
                                    helperText={this.state.errors?.confirm_password}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center", flexDirection: "row-reverse"}}>
                                <MaterialButton
                                    dataTestId={"button_submit"}
                                    name={"Update"}
                                    onClick={this.submitClick.bind(this)}
                                    spinner={this.state.loading}
                                    disabled={this.state.loading}
                                />
                                <Link to={'/password/reset'} className="App-Form-Link">Link expired?</Link>
                            </Box>
                        </div>
                    </Paper>
                </React.Fragment>
            </div>
        );
    }

}

export default withRouter(withSnackbar(UpdatePasswordPage));