import {apiUrl, axios} from "./apiWrapper";
import jwt_decode from "jwt-decode";

export const getAuthenticationToken = async (username, password) => {
    const response = await axios.post(`${apiUrl}/security/authenticate`, {
        username: username,
        password: password
    })
    return response.data;
};

export const login = async (username, password) => {
    const {token} = await getAuthenticationToken(username, password);
    sessionStorage.setItem('token', token);
    setUserDetails(token)
    return token;
}

export const clearSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userDetails');
    sessionStorage.removeItem('operator');
}

export const setUserDetails = (token) => {
    const decoded = jwt_decode(token);
    if (decoded?.userTypes == null || !decoded.userTypes.includes('OPERATOR')) {
        throw Error("Invalid user");
    }
    const userDetails = {
        operators: decoded?.operators,
        roles: decoded?.roles,
        userTypes: decoded?.userTypes,
    }
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
}