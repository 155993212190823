import React, {useRef} from 'react';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {getAllCases, useCases} from "../api/casesApi";
import {Link as ReactLink, useHistory} from "react-router-dom";
import {Box, Button, Typography, Breadcrumbs, Link as MaterialLink, Paper, TextField, Tooltip} from "@mui/material";
import {formatContactName} from "../utils/contactFormatter";
import {utils, writeFile} from 'xlsx';
import MaterialButton from "./MaterialButton";
import {displayDate, displayDueDate, isoDate} from "../utils/dateUtils";
import {customFormatter, tooltipCell, statusCell} from "../utils/dataGridHelpers";
import {ExpandLess, ExpandMore, Replay} from "@mui/icons-material";
import CaseAdvancedSearch from "./CaseAdvancedSearch";
import {urlSlug} from "../utils/stringUtils";
import {mapCasesForExport} from "../utils/caseMapper";
import {useStateStore, useClearStateStore} from "../providers/StateStoreProvider";

const columns = [
    {field: 'verificationCode', headerName: 'Verification Code', width: 190, sortable: true},
    {
        field: 'appellant',
        headerName: 'Appellant',
        flex: 1,
        sortable: true,
        valueFormatter: customFormatter(formatContactName, '-'),
        renderCell: tooltipCell
    },
    {field: 'vehicleRegistration', headerName: 'Vehicle Reg', width: 155, sortable: true},
    {
        field: 'submissionDate',
        headerName: 'Submission Date',
        width: 162,
        sortable: true,
        valueFormatter: customFormatter(displayDate, '-')
    },
    {
        field: 'dueDate',
        headerName: 'Due Date',
        width: 120,
        sortable: true,
        valueGetter: params => displayDueDate(params.row.submissionDate, '-')
    },
    {field: 'assessorName', headerName: 'Assessor', flex: 1, sortable: true, renderCell: tooltipCell},
    {field: 'status', headerName: 'Status', width: 120, sortable: true, renderCell: statusCell},
    {field: 'condition', headerName: 'Condition', flex: 1, sortable: true, renderCell: tooltipCell},
    {field: 'decision', headerName: 'Decision', width: 135, sortable: true},
];

const nonSearchableFields = ['dueDate']
const searchableColumns = columns.filter(column => !nonSearchableFields.includes(column.field))

const Cases = (props) => {
    const token = sessionStorage.getItem('token');
    const history = useHistory();
    const [pageNum, setPageNum] = useStateStore('casesPageNum', 0);
    const [pageSize, setPageSize] = useStateStore('casesPageSize', 50);
    const [sortValue, setSortValue] = useStateStore('sortValue', 'submissionDate,desc')
    const [searchParams, setSearchParams] = useStateStore('filters', []);
    const [basicSearchValue, setBasicSearchValue] = useStateStore('basicSearchValue', '');
    const [showAdvancedSearch, setShowAdvancedSearch] = useStateStore('advancedSearch', false);
    const {
        isLoading,
        casesPage
    } = useCases(props.operator?.operatorCode, token, pageNum, pageSize, searchParams, sortValue);
    const searchTimer = useRef();
    const {reset: clearStateStore, filtersAreInitial} = useClearStateStore();

    function clearSearchValues() {
        clearStateStore()
    }

    const handleRowClick = (row) => {
        history.push(`/case/${row.id}`)
    }

    const handleSearchToggle = () => {
        setShowAdvancedSearch(!showAdvancedSearch)
        setBasicSearchValue('')
    }

    function handleSearchInput(params) {
        setPageNum(0)
        setSearchParams(params)
    }

    async function exportCSV() {
        const operatorCode = props.operator?.operatorCode
        const allCases = await getAllCases(operatorCode, token, casesPage.totalElements, 1000, searchParams, sortValue)
        const workSheet = utils.json_to_sheet(mapCasesForExport(allCases));
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, workSheet, "Cases");
        const date = isoDate(new Date())
        const operator = urlSlug(props.operator.name)
        writeFile(workBook, `cases-${operator}-${date}.csv`);
    }

    function handleBasicSearchChange(e) {
        const {value} = e.target;
        clearTimeout(searchTimer.current)
        setBasicSearchValue(value)
        searchTimer.current = setTimeout(() => {
            let params = []
            if (value.length > 2) {
                params = [
                    {filter: 'allFields'},
                    {value: value}
                ]
            }
            setSearchParams(prevParams => {
                // Don't update state if params are empty
                if (!prevParams.length && !params.length) return prevParams;
                return params;
            });
            setPageNum(0)
        }, 600)
    }

    const handleSortModelChange = (newModel) => {
        if (newModel !== undefined
            && newModel.length > 0
            && newModel[0]["sort"] !== undefined
            && newModel[0]["field"] !== undefined) {

            switch (newModel[0]["field"]) {
                case 'appellant':
                    setSortValue('appellantPrimaryContactEntity_lastName,' + newModel[0].sort);
                    break;
                case 'dueDate':
                    setSortValue('submissionDate,' + newModel[0].sort);
                    break;
                case 'assessorName':
                    setSortValue('assessorEntity_name,' + newModel[0].sort);
                    break;
                default:
                    setSortValue('' + newModel[0].field + ',' + newModel[0].sort);
            }

        } else {
            setSortValue('submissionDate,desc');
        }
    };

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink component={ReactLink} to="/account" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Cases</Typography>
            </Breadcrumbs>
            <div>
                <Box display="flex" alignItems="center">
                    <h1 style={{marginRight: 24}}>Cases</h1>
                    <Tooltip title="Search requires a minimum of 3 characters" disableFocusListener>
                        <TextField
                            data-testid="cases-search"
                            name="search"
                            placeholder="Search"
                            variant="standard"
                            value={basicSearchValue}
                            onChange={handleBasicSearchChange}
                        />
                    </Tooltip>
                    <Button
                        variant="text"
                        onClick={handleSearchToggle}
                        data-testid="toggle-advanced-search"
                    >{showAdvancedSearch ? <ExpandLess/> : <ExpandMore/>}
                        Advanced search
                    </Button>

                    <Button
                        variant="text"
                        onClick={clearSearchValues}
                        style={{minWidth: 0, marginLeft: 'auto'}}
                        startIcon={<Replay/>}
                        disabled={filtersAreInitial}
                    >Reset filters</Button>
                </Box>
                {showAdvancedSearch && (
                    <Box mb={2}>
                        <CaseAdvancedSearch columns={searchableColumns} onChange={handleSearchInput}/>
                    </Box>
                )}
                <Box pb={2}>
                    <Paper elevation={3} style={{height: 632}}>
                        <DataGridPro
                            rows={casesPage ? casesPage.content : []}
                            columns={columns}
                            getRowId={row => row.caseId}
                            loading={isLoading}
                            pageSize={pageSize}
                            page={casesPage ? casesPage.number : 0}
                            paginationMode="server"
                            sortingMode="server"
                            rowCount={casesPage ? casesPage.totalElements : 0}
                            onPageChange={setPageNum}
                            onRowClick={handleRowClick}
                            onPageSizeChange={setPageSize}
                            onSortModelChange={handleSortModelChange}
                            rowsPerPageOptions={[50, 100, 250, 500]}
                            disableColumnFilter
                            disableSelectionOnClick
                            pagination
                        />
                    </Paper>
                </Box>
                {casesPage && <MaterialButton
                    dataTestId="export-csv"
                    onClick={exportCSV}
                    disabled={casesPage?.content.length === 0}
                    name="Download CSV"
                />}
            </div>
        </div>
    );
};

export default Cases;
