import React, {useEffect, useState} from 'react';
import {
    CardContent,
    CircularProgress,
    Link as MaterialLink,
    TextField,
    Button,
    Breadcrumbs,
} from '@mui/material';
import MaterialButton from "../MaterialButton";
import {Link as ReactLink, useHistory} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {postEvidence, useComments} from "../../api/evidenceApi";
import {storageType, useStorage} from "../../store";
import Grid from '@mui/material/Grid';
import {ReactComponent as Logo} from '../../images/Inbox-Arrow-Up.svg';
import makeStyles from '@mui/styles/makeStyles';
import {getCase, updateCaseState, useCase} from "../../api/casesApi";
import FileAttachments from "./FileAttachments";
import withSnackbar from "../WithSnackbar";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const EvidenceView = ({dataTestId, caseId, showToast}) => {
    const [operator] = useStorage(storageType.SESSION, "operator");
    const {isLoading, error, evidence} = useComments(operator.operatorCode, caseId, sessionStorage.getItem('token'));
    const {caseObject} = useCase(caseId, operator.operatorCode, sessionStorage.getItem('token'));
    const history = useHistory();
    const [comment, setComment] = useState(evidence?.comment || "")
    const [commentError, setCommentError] = useState("");
    const [disableFinishButton, setDisableFinishButton] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rawEvidenceList, setRawEvidenceList] = useState([]);
    const classes = useStyles();

    const handleSubmit = async () => {
        setDisableFinishButton(true);
        setIsSubmitting(true);

        const caseRefresh = await getCase(caseId, operator.operatorCode, sessionStorage.getItem('token'));

        if (caseRefresh?.condition !== 'AWAITING_OPERATOR_EVIDENCE') {
            showToast("This case is no longer awaiting operator evidence", 'error');
            setDisableFinishButton(false);
            setIsSubmitting(false);
            return;
        }

        try {
            await postEvidence(operator.operatorCode, caseId, createComment(evidence, caseId, comment), sessionStorage.getItem('token'), rawEvidenceList, caseObject?.verificationCode)
                .then((error) => {
                    if (error) {
                        throw new Error(`Some evidence could not be uploaded: ${error}`);
                    }
                })

            await updateCaseState(operator.operatorCode, caseId, "EVIDENCE_UPLOAD_COMPLETE", sessionStorage.getItem('token'));

            history.goBack();
        } catch (error) {
            showToast(error.message, 'error', 8000);
        }

        setDisableFinishButton(false);
        setIsSubmitting(false);
    }

    const handleInputChange = (event) => {
        const {value} = event.target;
        setComment(event.target.value);

        if (value.match(/[<>]/)) {
            setCommentError("Comments cannot contain < or > characters")
            return;
        }

        if (value.length > 10000) {
            setCommentError("Comments cannot be over 10,000 characters long")
            return;
        }

        setCommentError("");
        setDisableFinishButton(false);
    }

    const selectFile = (event) => {
        if (event.target.files[0].size > 31457280) {
            showToast('The file chosen is larger than 30MB.', 'error');
        } else {
            setRawEvidenceList(rawEvidenceList => [...rawEvidenceList, event.target.files[0]]);
        }
    }

    const createComment = (evidence, caseId, comment) => {
        const defaultComment = {
            userType: 'OPERATOR',
            commentType: 'OPERATOR_COMMENT',
            caseId: caseId,
            comment: ""
        }

        return Object.assign(defaultComment, evidence, {comment})
    }

    useEffect(() => {
        setComment(evidence?.comment || "");
    }, [evidence])

    return (
        <div>
            <div style={{width: '90%', margin: '28px auto'}} data-testid={dataTestId}>
                <CssBaseline/>
                <Container maxWidth="lg">
                    <Breadcrumbs aria-label="breadcrumb">
                        <MaterialLink component={ReactLink} to="/portal" className={"App-Breadcrumb-Link"}>
                            <u>Home</u>
                        </MaterialLink> /&nbsp;
                        <MaterialLink component={ReactLink} to={"/case/" + caseObject?.caseId} className={"App-Breadcrumb-Link"}>
                            <u>Appeal</u>
                        </MaterialLink> /
                        <Typography color="textPrimary">Submit Evidence</Typography>
                    </Breadcrumbs>
                    {isLoading
                        ?
                        <CardContent><CircularProgress/></CardContent>
                        : error ? <p>{error}</p>
                        : <div>
                            <div className={"App-Evidence-Header"}>
                                <Typography variant="h4" >
                                    <b>Submit Your Evidence</b>
                                </Typography>

                                <p className={'App-Evidence-Summary'}>Verification code: {caseObject?.verificationCode}</p>

                                <p className={'App-Evidence-comment-box-upper'}>
                                    Please explain your case summary based on the ground for appeal in the previous case page
                                    <span className={'App-Evidence-red'}>*</span>
                                </p>

                                <TextField
                                    label="Case Summary"
                                    multiline
                                    fullWidth
                                    variant="filled"
                                    rows={10}
                                    onChange={handleInputChange}
                                    name="comments"
                                    data-testid="commentId"
                                    value={comment}
                                    helperText={commentError}
                                    error={commentError !== ''}
                                    style={{marginBottom: 12}}
                                />
                                <p className={'App-Evidence-comment-box-lower'}>
                                    Please provide comments specific to the motorist case and evidence. Please add any comments on the motorist evidence here.
                                </p>

                                <Typography variant="h5" gutterBottom>Supporting evidence</Typography>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <label htmlFor="contained-button-file">
                                            <input
                                                data-testid="file_select"
                                                accept="*/*"
                                                className={classes.input}
                                                id="contained-button-file"
                                                type="file"
                                                onChange={selectFile}
                                            />
                                            <Button data-testid="upload_evidence_button" component="span" startIcon={<Logo />}>File Upload</Button>
                                        </label>
                                    </Grid>
                                    <Grid item>

                                    </Grid>
                                </Grid>
                            </div>

                            <FileAttachments files={rawEvidenceList} setFiles={setRawEvidenceList}/>

                            <div style={{marginTop: 24}}>
                                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <MaterialButton dataTestId="back_button" name="Back" onClick={() => history.goBack()}/>
                                    </Grid>
                                    <Grid item>
                                        <MaterialButton disabled={disableFinishButton} spinner={isSubmitting} dataTestId="finish_button" name="Finish" onClick={handleSubmit}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </Container>
            </div>
        </div>
    );
};

export default withSnackbar(EvidenceView);
