import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Movie from "@mui/icons-material/Movie";
import Email from "@mui/icons-material/Email";
import Image from "@mui/icons-material/Image";
import Description from "@mui/icons-material/Description";
import FileCopy from "@mui/icons-material/FileCopy"
import People from "@mui/icons-material/People"
import IconButton from "@mui/material/IconButton";

const categoryMap = {};
categoryMap['MOTORIST_PHOTO'] = ['Motorist Photo', <Image/>];
categoryMap['MOTORIST_VIDEO'] = ['Motorist Video', <Movie/>];
categoryMap['MOTORIST_EMAIL'] = ['Motorist Email', <Email/>];
categoryMap['MOTORIST_THIRD_PARTY'] = ['Motorist Third Party', <People/>];
categoryMap['OP_CASE_SUMMARY'] = ['Operator Case Summary', <FileCopy/>];
categoryMap['OP_REGISTERED_KEEPER'] = ['Operator Registered Keeper', <FileCopy/>];
categoryMap['OP_ORIGINAL_REPRESENTATION'] = ['Operator Original Representation', <FileCopy/>];

const getIcon = (category) => {
    let catVal = categoryMap[category];

    if (catVal)
        return catVal[1];
    else
        return (<Description/>);
}

const getTooltipText = (category) => {
    let catVal = categoryMap[category];

    if (catVal)
        return catVal[0];
    else
        return 'File';
}

const CategoryIcon = ({category}) => {
    return (
        <div>
            <Tooltip title={getTooltipText(category)}>
                <IconButton style={{color: '#0E2361'}} size="large">{getIcon(category)}</IconButton>
            </Tooltip>
        </div>
    );
}

export default CategoryIcon;