export const decisionColour = (decision) => {

    if (decision==='ALLOWED'){
        return '#4AF78D';
    }else if(decision==='REFUSED'){
        return '#F5635B';
    }else{
        return '#FFA726';
    }

}