import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useCase} from "../../api/casesApi";
import {Breadcrumbs, Link as MaterialLink, Paper, Tabs, Box, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {storageType, useStorage} from "../../store";
import {tabProps} from "../../utils/tabbingProps";
import TabPanel from "./TabPanel";
import CaseTab from "./CaseTab";
import {ReactComponent as UploadIcon} from '../../images/Inbox-Arrow-Up.svg';
import AnswersView from "./AnswersView";
import AssessmentView from "./AssessmentView";
import CommentsView from "./CommentsView";
import DocumentsView from "./DocumentsView";
import {displayDate, displayDueDate} from "../../utils/dateUtils";
import {statusColour} from "../../utils/statusColour";
import {conditionMapper} from "../../utils/conditionMapper";
import StatusChip from "../StatusChip";
import {formatContactName, formatContactEmail} from "../../utils/contactFormatter";
import {caseSourceMapper} from "../../utils/caseSourceMapper";
import WithdrawalTab from './WithdrawalTab'
import withSnackbar from '../WithSnackbar'
import CustomTab from "./CustomTab";

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        color: theme.palette.text.secondary,
        '& p': {
            marginTop: 0
        }
    }
}));

const CaseView = ({dataTestId, caseId, showToast}) => {
    const [operator] = useStorage(storageType.SESSION, "operator");
    const [summaryTabValue, setSummaryTabValue] = useState(0);
    const [caseCondition, setCaseCondition] = useState(null)
    const {caseObject} = useCase(caseId, operator.operatorCode, sessionStorage.getItem('token'), caseCondition);
    const classes = useStyles();

    const handleChangeSummaryTab = (event, newSummaryTabValue) => {
        setSummaryTabValue(newSummaryTabValue);
    };

    const handleError = (message) => {
        showToast(message, 'error')
    }

    const handleUpdate = (message) => {
        setCaseCondition(new Date())
        showToast(message);
    }

    return (
        <div style={{width: '90%', margin: '28px auto'}} data-testid={dataTestId}>
            <div >
                <Container maxWidth="lg">
                    <Breadcrumbs aria-label="breadcrumb">
                        <MaterialLink component={Link} to="/portal" className={"App-Breadcrumb-Link"}>
                            <u>Home</u>
                        </MaterialLink>
                        <Typography color="textPrimary">Appeal</Typography>
                    </Breadcrumbs>

                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="baseline">
                            <h1 style={{marginRight: 24}}>Appeal</h1>
                            <h2 style={{fontSize: 20, color: '#707070'}} data-testid="case-id">Case {caseObject?.verificationCode}</h2>
                        </Box>
                        {caseObject?.condition === 'AWAITING_OPERATOR_EVIDENCE' && caseObject?.status === 'NEW' && (
                            <Button component={Link} to={`/case/${caseId}/evidence`} data-testid="submit_evidence" startIcon={<UploadIcon />}>Submit Evidence</Button>
                        )}
                    </Box>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper elevation={3} className={classes.paper}>
                                <Box p={2}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={summaryTabValue}
                                                TabIndicatorProps={{
                                                    style: { display: "none" },
                                                }}
                                                onChange={handleChangeSummaryTab}
                                                aria-label="Vertical tabs example"
                                            >
                                                <CaseTab label="Appeal Summary" {...tabProps(0)} />
                                                <CaseTab label="Appellant Details" {...tabProps(1)} data-testid="case_tab"/>
                                                <CaseTab label="Appeal Withdrawal" {...tabProps(2)} data-testid="withdraw_tab" />
                                                <CaseTab label="Custom" {...tabProps(3)} data-testid="custom_tab" />
                                            </Tabs>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TabPanel value={summaryTabValue} index={0}>
                                                <p><strong>Submission Date: </strong>{displayDate(caseObject?.submissionDate)}</p>
                                                <p><strong>Due Date: </strong>{displayDueDate(caseObject?.submissionDate)}</p>
                                                <p><strong>Verification Code: </strong>{caseObject?.verificationCode}</p>
                                                <p><strong>Vehicle Registration: </strong>{caseObject?.vehicleRegistration}</p>
                                                <p><strong>PCN Number: </strong>{caseObject?.pcn}</p>
                                                <div style={{marginBottom: '1em'}}><strong>Status: </strong><StatusChip size="small" label={caseObject?.status} statusColor={statusColour(caseObject?.status)}/></div>
                                                <p><strong>Condition: </strong>{conditionMapper(caseObject?.condition)}</p>
                                                <p><strong>Source: </strong>{caseSourceMapper(caseObject?.caseSource)}</p>
                                            </TabPanel>
                                            <TabPanel value={summaryTabValue} index={1}>
                                                <p><strong>Appellant Name: </strong>{formatContactName(caseObject?.appellant, "N/A")}</p>
                                                <p><strong>Appellant Email: </strong>{formatContactEmail(caseObject?.appellant, "N/A")}</p>
                                                <p><strong>Motorist Name: </strong>{formatContactName(caseObject?.motorist, "N/A")}</p>
                                                <p><strong>Motorist Email: </strong>{formatContactEmail(caseObject?.motorist, "N/A")}</p>
                                            </TabPanel>
                                            <TabPanel value={summaryTabValue} index={2}>
                                                <WithdrawalTab caseObject={caseObject} onError={handleError} onUpdate={handleUpdate} />
                                            </TabPanel>
                                            <TabPanel value={summaryTabValue} index={3}>
                                                <CustomTab caseObject={caseObject} onError={handleError} onUpdate={handleUpdate}/>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper elevation={3} className={classes.paper}>
                                <DocumentsView
                                    operator={operator}
                                    caseId={caseId}
                                    useStyles={useStyles}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <AssessmentView caseObject={caseObject} caseId={caseId} useStyles={useStyles}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CommentsView operator={operator} caseId={caseId} useStyles={useStyles}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AnswersView operator={operator} caseId={caseId} useStyles={useStyles}/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default withSnackbar(CaseView);