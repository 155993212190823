import React, {useState} from 'react';
import {addUser, updateUser} from "../../api/usersApi";
import {
    Breadcrumbs, Checkbox,
    FormControl, FormControlLabel,
    InputLabel,
    Link as MaterialLink, MenuItem,
    Paper,
    Select,
    Box, Grid,
    TextField, Typography,
    Container,
} from "@mui/material";
import {Link as ReactLink, useHistory} from "react-router-dom";
import {storageType, useStorage} from "../../store";
import MaterialButton from "../MaterialButton";
import {getAllowedRoles} from "../../utils/roles";
import withSnackbar from "../WithSnackbar";

export const AddEditUserView = withSnackbar(({dataTestId, showToast}) => {
    const history = useHistory();
    const location = history?.location;
    const user = location?.state?.user
    const [name, setName] = useState(user ? user.name : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [disabled, setDisabled] = useState(user ? user.disabled : false);
    const [userDetails] = useStorage(storageType.SESSION, 'userDetails', null);
    const [role, setRole] = useState(user ? user.roles : '');
    const allowedRoles = getAllowedRoles(userDetails)

    const handleSubmit = () => {
        if (!formIsValid()) {
            return showToast("Please enter all details", 'error');
        }
        user ? handleUpdate() : handleSave();
    }

    const formIsValid = () => {
        return name && name.trim().length
        && email && email.trim().length
        && role && role.trim().length
    }

    const handleUpdate = () => {
        const newUser = {
            id: user.id,
            name: name,
            email: email,
            roles: role,
            disabled: disabled
        };
        updateUser(newUser, sessionStorage.getItem('token'))
            .then(result => {
                if (result.errorMessage != null) {
                    return showToast("Error updating user: " + result.errorMessage, 'error')
                }
                history.push('/users');
            });
    }

    const handleSave = () => {
        const newUser = {
            name: name,
            email: email,
            roles: role,
            disabled: disabled
        };
        addUser(newUser, sessionStorage.getItem('token'))
            .then(result => {
                if (result.errorMessage != null) {
                    return showToast("Error creating user: " + result.errorMessage, 'error')
                }
                history.push('/users');
            });
    }

    return <div style={{width: '90%', margin: '28px auto'}}>
        <Breadcrumbs aria-label="breadcrumb">
            <MaterialLink component={ReactLink} to="/portal" className={"App-Breadcrumb-Link"}>
                <u>Home</u>
            </MaterialLink>
            <MaterialLink component={ReactLink} to="/users" className={"App-Breadcrumb-Link"}>
                <u>Users</u>
            </MaterialLink>
            <Typography color="textPrimary">{user ? 'Edit User' : 'Create User'}</Typography>
        </Breadcrumbs>

        <Container maxWidth="lg" data-testid={dataTestId}>
            <h1>{user ? 'User Details' : 'New User Details' }</h1>
            <Paper elevation={3}>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="name-field"
                                    value={name}
                                    label="Name"
                                    inputProps={{ maxLength: 70 }}
                                    data-testid="name-field"
                                    placeholder="Name of user"
                                    onChange={event => setName(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="email-field"
                                    value={email}
                                    label="Email"
                                    inputProps={{ maxLength: 100 }}
                                    placeholder="Email address"
                                    data-testid="email-field"
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel id="role-label">Role</InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role-select"
                                    value={role}
                                    data-testid="role-select"
                                    onChange={event => setRole(event.target.value)}>
                                    {allowedRoles.map(allowedRole =>
                                        <MenuItem key={allowedRole} value={allowedRole}>{allowedRole}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                id="disabled-checkbox"
                                data-testid="disabled-checkbox"
                                control={<Checkbox color="primary"/>}
                                label="Disabled"
                                labelPlacement="start"
                                checked={disabled}
                                onChange={event => {
                                    setDisabled(event.target.checked)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" pt={3}>
                        <MaterialButton data-testid="back_button" name="Back" onClick={() => history.goBack()}/>
                        <MaterialButton data-testid="saveUser_button" name="Save" onClick={handleSubmit}/>
                    </Box>
                </Box>
            </Paper>
        </Container>
    </div>;
});
