import PropTypes from 'prop-types'
import {TextField} from "@mui/material";
import MaterialButton from "../MaterialButton";
import {useEffect, useState} from "react";
import {updateCustomFields} from "../../api/casesApi";
import {storageType, useStorage} from "../../store";

const CustomTab = ({caseObject, onError, onUpdate}) => {
    const maxLength = 255;

    const [operator] = useStorage(storageType.SESSION, "operator");
    const [customField1, setCustomField1] = useState(caseObject?.customField1 || "");
    const [customField2, setCustomField2] = useState(caseObject?.customField2 || "");
    const [isLoading, setIsLoading] = useState(false);
    const [field1Error, setField1Error] = useState(null);
    const [field2Error, setField2Error] = useState(null);
    const [isField1Valid, setIsField1Valid] = useState(true);
    const [isField2Valid, setIsField2Valid] = useState(true);

    useEffect(() => {
        setCustomField1(caseObject?.customField1 || "");
        setCustomField2(caseObject?.customField2 || "");
    }, [caseObject]);

    function handleField1Input(e) {
        const {value} = e.target;
        setCustomField1(value);
    }

    function handleField2Input(e) {
        const {value} = e.target;
        setCustomField2(value);
    }

    function validate() {
        return validateField(customField1, setIsField1Valid, setField1Error)
            & validateField(customField2, setIsField2Valid, setField2Error);
    }

    function validateField(field, setFieldValid, setFieldError) {
        if (field.match(/[<>]+/g)) {
            setFieldValid(false);
            setFieldError('Must not contain <> characters');
            return false;
        } else {
            setFieldValid(true);
            setFieldError(null);
            return true;
        }
    }

    const handleSubmit = () => {
        if (validate()) {
            setIsLoading(true);
            updateCustomFields(operator.operatorCode, sessionStorage.getItem('token'), caseObject.caseId, customField1, customField2)
                .then(error => {
                    if (error.errorMessage != null) {
                        onError(error.errorMessage);
                    } else {
                        onUpdate('Custom fields updated');
                    }
                }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <>
            <TextField
                label="Custom Field 1"
                multiline
                fullWidth
                variant="filled"
                rows={3}
                onInput={handleField1Input}
                onChange={handleField1Input}
                helperText={field1Error}
                error={!isField1Valid}
                inputProps={{maxLength: maxLength}}
                name="customField1"
                style={{marginTop: 6, marginBottom: isField1Valid ? 22 : 0}}
                data-testid="custom1-textfield"
                value={customField1}
            />
            <TextField
                label="Custom Field 2"
                multiline
                fullWidth
                variant="filled"
                rows={3}
                onInput={handleField2Input}
                onChange={handleField2Input}
                helperText={field2Error}
                error={!isField2Valid}
                inputProps={{maxLength: maxLength}}
                name="customField2"
                style={{marginTop: 6, marginBottom: isField2Valid ? 22 : 0}}
                data-testid="custom2-textfield"
                value={customField2}
            />
            <MaterialButton
                name="Save"
                disabled={isLoading}
                onClick={handleSubmit}
                spinner={isLoading}
                dataTestId="save-custom-fields-button"
            />
        </>
    )
}

CustomTab.propTypes = {
    name: PropTypes.object,
};

export default CustomTab;