import {apiUrl, axios} from "./apiWrapper";

export const resetPassword = async (username) => {
    return axios.post(`${apiUrl}/security/reset`, {
        user: username,
    }).then(res => {
        return res.data;
    }).catch(function(error)  {
        throw new Error(error);
    });
};

export const updatePassword = async (password, token) => {
    return axios.post(`${apiUrl}/security/update`, {
        token: token,
        password: password
    }).then(res => {
        return res.data;
    }).catch(function(error)  {
        throw new Error(error);
    });
};