import {storageType, getItem} from "../store";

export const PARENT = 'PARENT';
export const CHILD = 'CHILD';
export const SUPERUSER = 'SUPERUSER';
export const USER_MANAGEMENT = [PARENT, SUPERUSER];

export function getAllowedRoles(userDetails) {
    return userDetails?.roles?.includes('PARENT')
        ? ['SUPERUSER', 'CHILD']
        : userDetails?.roles?.includes('SUPERUSER')
            ? ['CHILD']
            : []
}

export function getUserRoles() {
    const userDetailsString = getItem(storageType.SESSION, 'userDetails');
    if (!userDetailsString) {
        return []
    }
    const userDetails = JSON.parse(userDetailsString)
    return userDetails.roles || []
}

export function hasRole(...roles) {
    const userRoles = getUserRoles()
    return roles.flat(2).some(role => userRoles.includes(role))
}