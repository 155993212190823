/* eslint-disable react/prop-types */
import React from "react";
import {FormControl, FormHelperText, InputLabel, Select as MaterialSelect} from "@mui/material";

export default function Select({dataTestId, name, label, children, helperText, error, ...restProps}) {
    return (
        <div className="App-Input-TextBox">
            <FormControl fullWidth variant="filled" required={restProps.required || false}>
                <InputLabel id={name + "-select-label"}>{label}</InputLabel>
                <MaterialSelect data-testid={dataTestId} labelId={name + "-select-label"} error={error} name={name} {...restProps}>
                    {children}
                </MaterialSelect>
                {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    )
}