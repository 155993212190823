import React from 'react';
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    responsiveFontSizes,
} from "@mui/material";
import PropTypes from "prop-types";
let defaultTheme = createTheme()

const palette = {
    ...defaultTheme.palette,
    primary: {
        main: '#0E2361'
    }
}

let theme = createTheme({
    palette,
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    borderRadius: 50,
                    minWidth: '135px',
                    textTransform: 'none'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '1em'
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: palette.grey[50],
                        '&:hover': {
                            backgroundColor: palette.grey[100],
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                    backgroundColor: defaultTheme.palette.common.white,
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: defaultTheme.palette.grey[100],
                    },
                },
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'filled',
            },
        }
    },
    typography: {
        h1: {
            fontSize: "2.125rem"
        },
        subtitle1: {
            color: palette.primary.main,
            fontWeight: 600,
            fontSize: '1rem'
        },
        subtitle2: {
            color: palette.primary.main,
            fontWeight: 600,
            fontSize: "0.875rem"
        }
    }
})

theme = responsiveFontSizes(theme);

const CustomThemeProvider = ({children}) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
}

CustomThemeProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default CustomThemeProvider