import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import {
    Box,
    ClickAwayListener, Divider,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import {
    AccountCircle,
    EmojiTransportation,
    ExitToAppRounded,
    SupervisedUserCircle
} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import DomPurify from "dompurify";
import {useOperators} from "../api/operatorsApi";
import {hasRole, USER_MANAGEMENT} from "../utils/roles";

export default function DenseAppBar({onLogOut}) {
    const {isLoading, operators} = useOperators(sessionStorage.getItem('token'));
    const history = useHistory();

    // Menu Toggle stuff
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    // end of menu toggle stuff

    const changeOperator = () => {
        closePopper();
        history.push('/account');
    };

    const userManagement = () => {
        closePopper();
        history.push('/users');
    };

    const closePopper = () => {
        if (open) {
            setOpen(false);
        }
    }

    return (
        <AppBar position="static" data-testid="menu-app-bar">
            <Toolbar variant="dense" className={"App-Menu-Bar"} >
                <Box display='flex' flexGrow={1}>
                    <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-white.svg" class="App-Menu-Bar-Logo" alt="menu bar logo"/>`)}} />
                </Box>
                <IconButton
                    data-testid={"profile-icon-button"}
                    aria-label="account"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    size="large">
                    <AccountCircle fontSize={"large"} className={"App-Menu-Bar-Account-Button"} />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={"App-Menu-Bar-Account-Menu"}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList data-testid={"profile_menu"} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        { hasRole(USER_MANAGEMENT) && ([
                                            <MenuItem key={"menuitem_user_management"} data-testid={"menuitem_user_management"} onClick={userManagement}>
                                                <ListItemIcon>
                                                    <SupervisedUserCircle fontSize="small"/>
                                                </ListItemIcon>
                                                User Management
                                            </MenuItem>,
                                            <Divider key={"divider_user_management"} light />
                                            ])
                                        }
                                        {!isLoading && operators?.length > 1 && (
                                            <MenuItem data-testid={"menuitem_change_account"} onClick={changeOperator}>
                                                <ListItemIcon>
                                                    <EmojiTransportation fontSize="small"/>
                                                </ListItemIcon>
                                                Change account
                                            </MenuItem>
                                        )}
                                        <Divider light />
                                        <MenuItem data-testid={"menuitem_logout"} onClick={onLogOut}>
                                            <ListItemIcon>
                                                <ExitToAppRounded fontSize="small"/>
                                            </ListItemIcon>
                                            Log out
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    );
}
