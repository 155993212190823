import {useEffect, useState} from "react";
import {apiUrl, axios} from "./apiWrapper";

export const useUsers = (token) => {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getUsers = async () => {
            return await axios.get(`${apiUrl}/security/users`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(error.message);
                return null;
            });
        }
        getUsers().then(result => {
            if (mounted) {
                setUsers(result ? result.data : {users: [], operators: []});
                setIsLoading(false);
            }
        });

        return () => mounted = false;
    }, [token]);

    return {
        isLoading,
        error,
        users,
    };
}

export const addUser = async (userData, token) => {
    let result = {errorMessage: null, status: null, data: null};

    if (userData) {
        await axios.post(`${apiUrl}/security/users`,
            userData, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                result.status = response.status;
                result.data = response.data;
            })
            .catch(error => {
                if (error.response?.data?.message && typeof error.response?.data?.message === "string") {
                    result.errorMessage = error.response.data.message;
                } else {
                    result.errorMessage = error.message;
                }
            });
    }
    return result;
};

export const updateUser = async (userData, token) => {
    let result = {errorMessage: null, status: null, data: null};

    if (userData) {
        await axios.put(`${apiUrl}/security/users`,
            userData, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                result.status = response.status;
                result.data = response.data;
            })
            .catch(error => {
                if (error.response?.data?.message && typeof error.response?.data?.message === "string") {
                    result.errorMessage = error.response.data.message;
                } else {
                    result.errorMessage = error.message;
                }
            });
    }
    return result;
};
