import React from 'react';
import {
    CardContent,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import validateUrl from "../../utils/validateUrl";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import {useEvidenceForCase} from "../../api/evidenceApi";
import {displayDate} from "../../utils/dateUtils";
import CategoryIcon from "./CategoryIcon";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        maxHeight: 10,
        fontWeight: "bold",
        padding: "6px 0px 6px 10px",
        noWrap: true,
    },
    body: {
        fontSize: 14,
        padding: "6px 0px 6px 10px",
    },
}))(TableCell);

const DocumentsView = ({operator, caseId, useStyles}) => {
    const {isLoading, evidenceForCase} = useEvidenceForCase(operator.operatorCode, caseId,
        sessionStorage.getItem('token'));
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <div style={{minWidth: 100, minHeight: 49, backgroundColor: '#F9F9F9', borderRadius: '4px 4px 0 0', padding: 10}}>
                <strong style={{fontSize: 16 }}>Documents</strong>
            </div>
            {isLoading
                ?
                <CardContent><CircularProgress/></CardContent>
                :
                <TableContainer style={{maxHeight: 280, borderRadius: 4}}>
                    <Table aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell >Document Name</StyledTableCell>
                                <StyledTableCell align="left">Category</StyledTableCell>
                                <StyledTableCell align="center">Created On</StyledTableCell>
                                <StyledTableCell align="center">Download</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {evidenceForCase.map((row, i) => (
                                <TableRow key={i} data-testid={`document-${i}`}>
                                    <StyledTableCell component="th" scope="row" >{row.filename}</StyledTableCell>
                                    <StyledTableCell align="left"><CategoryIcon category={row.category}/></StyledTableCell>
                                    <StyledTableCell align="center">{displayDate(row.createdAt)}</StyledTableCell>
                                    <StyledTableCell align="center" className={'pointer'}>
                                        <a href={validateUrl(row.signedUrl)} download >
                                            <ArrowDropDownCircleIcon style={{color: '#0E2361'}}/>
                                        </a>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Paper>
    );
};

export default DocumentsView;