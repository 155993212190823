import {displayDateTime} from './dateUtils'
import {formatContactName} from './contactFormatter'

export function mapCasesForExport(cases) {
    return cases.map(caseObject => {
        caseObject.appellant = formatContactName(caseObject.appellant)
        caseObject.motorist = formatContactName(caseObject.motorist)
        caseObject.submissionDate = displayDateTime(caseObject.submissionDate)
        caseObject.withdrawalDate = displayDateTime(caseObject.withdrawalDate)
        caseObject.decisionDate = displayDateTime(caseObject.decisionDate)
        return caseObject;
    })
}