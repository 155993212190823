export const urlSlug = (string) => {
    return string
        .trim()
        .toLowerCase()
        .replace(/&/g, 'and')
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-_]/g, '');
}

export const capitalize = (str) => {
    if (!str) return '';
    let [first, ...rest] = str;
    return first.toUpperCase() + rest.join('')
}

export const genericMapper = (value) => {
    if (!value) return '';
    let newValue = value.toLowerCase()
    newValue = newValue.replace(/_/g, ' ');
    newValue = newValue.split(/\s+/).map(s => capitalize(s)).join(' ')
    return (newValue);
}