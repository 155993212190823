import React from "react";

const ErrorPage = ({ errorMessage }) => {
    return (
        <div>
            <h1 data-testid="error_page">{errorMessage}</h1>
        </div>
    );
}

export default ErrorPage;