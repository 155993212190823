import {useEffect, useState} from "react";
import {apiUrl, axios} from "./apiWrapper";

export const useAnswers = (operatorCode, caseId, token) => {
    const [isLoading, setIsLoading] = useState(true);
    const [answers, setAnswers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getAnswers = async () => {
            if (operatorCode) {
                return await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/answers`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                ).catch(error => {
                    setError(error.message)
                    return null;
                });
            }
        }
        getAnswers().then((result) => {
            if (mounted) {
                // setAnswers(result ? formatAnswersForUi(result.data) : null);
                setAnswers(result ? result.data : null);
                setIsLoading(false);
            }
        });

        return () => mounted = false;
    }, [operatorCode, caseId, token]);

    return {
        isLoading: isLoading,
        error: error,
        groundsForAppealList: answers,
    };
}

/*
const mapDummyAnswersDataForUi = () => {
    return formatAnswersForUi(answersData);
}

const formatAnswersForUi = (answers) => {
    return (
        answers.map(answer => formatAnswerForUi(answer))
    );
}

const formatAnswerForUi = (answer) => {
    return ({
        ...answer,
        createdDate: new Date(answer.createdDate)
    });
}
*/
