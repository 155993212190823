import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box} from "@mui/material"

const useStyles = makeStyles(() => ({
    root: {
        root: {
            backgroundColor: '#00000033',
            opacity: '0.09',
        },
    }
}));

const InfoCard = (props) => {
    const {dataTestId, textContent} = props;
    const classes = useStyles(props);

    return (
        <Box data-testid={dataTestId} className={classes.root} style={{marginBottom: '1em'}}>
            {textContent ? textContent : '-'}
        </Box>
    );
}

export default InfoCard;

