import React from "react";
import {Paper, Typography, Box} from "@mui/material";
import DomPurify from "dompurify";
import Input from "./Input";
import MaterialButton from "./MaterialButton";
import {Link, withRouter} from "react-router-dom";
import {resetPassword} from "../api/credentialApi";
import withSnackbar from "./WithSnackbar";

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            loading: false,
        }
    }

    submitClick = async (event) => {
        event.preventDefault();

        this.setState({loading: true});

        if (this.state.username.trim().length === 0) {
            this.setState({loading: false});
            return;
        }

        try {
            await resetPassword(this.state.username);
            this.setState({loading: false});
            this.props.history.push("/password/reset/success");
        } catch (error) {
            this.setState({loading: false});
            this.props.showToast("Unable to reset password at this time.", 'error')
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleKeyDown = async (event) => {
        if (event.keyCode === 13) { //Enter key keycode
            await this.submitClick(event);
        }
    };

    render() {
        return <div data-testid="reset_form">
            <Paper elevation={3} className="App-Password-Reset-Form">
                <div
                    dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}}/>
                <div className={"App-Password-Reset-SubForm"}>
                    <Typography variant="h5" className={"App-Login-Header"}>
                        <b>Reset Password</b>
                    </Typography>
                    <Typography style={{color: '#36454f', fontWeight: "bolder"}}>
                        Lost your password? Please enter your email address.
                        You will receive a link to create a new password via email.
                    </Typography>
                    <Input
                        dataTestId={"input_username"}
                        label={"Email"}
                        name={"username"}
                        type={"text"}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    />
                    <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center", flexDirection: "row-reverse"}}>
                        <MaterialButton
                            dataTestId={"button_submit"}
                            name={"Reset"}
                            onClick={this.submitClick.bind(this)}
                            spinner={this.state.loading}
                            disabled={this.state.loading}
                        />
                        <Link to={'/'} className="App-Form-Link">Have the password</Link>
                    </Box>
                </div>
            </Paper>
        </div>
    }
}

export default withRouter(withSnackbar(ResetPasswordPage));