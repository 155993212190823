import React, {useState} from "react";
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";

/*
    Higher order component used to give a component the ability to show a toast message.

    Usage:
        const MyComponent = (props) => {
            ...
        }
        export default withSnackbar(MyComponent)
 */
const withSnackbar = (WrappedComponent) => {
    return (props) => {
        const [isToastOpen, setIsToastOpen] = useState(false)
        const [toastMessage, setToastMessage] = useState('')
        const [toastSeverity, setToastSeverity] = useState('success')
        const [autoHideDuration, setAutoHideDuration] = useState(6000);

        function handleMessage(message, severity = 'success', duration = 4000) {
            setToastMessage(message)
            setToastSeverity(severity)
            setIsToastOpen(true)
            setAutoHideDuration(duration)
        }

        function handleCloseMessage() {
            setToastMessage('')
            setIsToastOpen(false)
        }

        return (
            <>
                <WrappedComponent showToast={handleMessage} {...props} />
                <Snackbar
                    open={isToastOpen}
                    onClose={handleCloseMessage}
                    autoHideDuration={autoHideDuration}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    data-testid={"toast-alert"}
                >
                    <Alert
                        variant="filled"
                        elevation={6}
                        severity={toastSeverity}
                        onClose={handleCloseMessage}
                    >
                        {toastMessage}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default withSnackbar