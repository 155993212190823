import React from 'react';
import {
    Divider,
    Paper,
    Box
} from '@mui/material';
import {Typography} from '@mui/material';
import InfoCard from "../InfoCard";
import StatusChip from "../StatusChip";
import {displayDate} from "../../utils/dateUtils"
import {decisionColour} from "../../utils/decisionColour"

const AssessmentView = ({caseObject, useStyles}) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={3}>
            <Box p={2}>
                <Typography variant="subtitle1"><b>Case Assessment</b></Typography>
                <Box pt={2}>
                    <p>
                        <strong>Assessor: </strong>
                        <Typography component={'span'} style={{display: 'inline-block', color: "#7BAFDF"}}>
                            {caseObject?.assessorName ? caseObject.assessorName : '-'}
                        </Typography>
                    </p>

                    <div style={{marginBottom: '1em'}}>
                        <strong>Decision: </strong>
                        <StatusChip size="small" label={caseObject?.decision ? caseObject.decision : "NOT YET ASSESSED"} statusColor={decisionColour(caseObject?.decision)}/>
                    </div>

                    <p><strong>Decision Date: </strong>{displayDate(caseObject?.decisionDate)}</p>
                    <Divider sx={{marginBottom: '1em'}} />
                    <p><strong>Assessor Summary of the Operator Case</strong></p>
                    <InfoCard
                        dataTestId={"input_assessor_summary_operator"}
                        textContent={caseObject?.summaryOperator}
                    />

                    <p><strong>Assessor Summary of the Appellant Case</strong></p>
                    <InfoCard
                        dataTestId={"input_assessor_summary_operator"}
                        textContent={caseObject?.summaryAppellant}
                    />

                    <p><strong>Summary of Reasons</strong></p>
                    <InfoCard
                        dataTestId={"input_assessor_summary_reasons"}
                        textContent={caseObject?.summaryReasons}
                    />
                </Box>
            </Box>
        </Paper>
    );
};

export default AssessmentView;