import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AuthVerify = ({logout, history}) => {
    history.listen(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
            const decodedJwt = jwt_decode(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                logout();
            }
        }
    });
    return null;
};

export default withRouter(AuthVerify);