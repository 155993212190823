import React from 'react';
import {
    Paper, List, ListItem, ListItemText, Box, CardContent, CircularProgress
} from '@mui/material';
import {displayDate} from "../../utils/dateUtils";
import {useComments} from "../../api/commentsApi";

const CommentsView = ({operator, caseId, useStyles}) => {
    const {
        isLoading,
        operatorComments
    } = useComments(caseId, operator.operatorCode, sessionStorage.getItem('token'))
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box p={2} >
                <strong>Operator Comments</strong>
                {isLoading
                    ?
                    <CardContent><CircularProgress/></CardContent>
                    :
                    <List data-testid="operatorComment">
                        {operatorComments.length === 0 && (
                            <ListItem disableGutters>
                                <ListItemText primary="No comments are available at this time."/>
                            </ListItem>
                        )}
                        {operatorComments.map((comment, index) => (
                            <ListItem key={comment.id + index} disableGutters>
                                <ListItemText primary={comment.comment} secondary={displayDate(comment.createdDate)}/>
                            </ListItem>
                        ))}
                    </List>
                }
            </Box>
        </Paper>
    );
};

export default CommentsView;