import React from 'react';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: (props) => props.statusColor,
        color: 'white',
    }
}));

const StatusChip = (props) => {
    const classes = useStyles(props);

    return (
        <Chip
            className={classes.root}
            data-testid={props.dataTestId}
            size={props.size}
            label={props.label}
        />
    );
}

export default StatusChip;