import React from 'react';
import {Box} from '@mui/material';
import {LoadingButton} from '@mui/lab';

const MaterialButton = ({ dataTestId, name, icon, spinner, ...restProps }) => {
    return (
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            <LoadingButton
                variant="contained"
                data-testid={dataTestId}
                startIcon={icon}
                loading={spinner}
                {...restProps}
            >{name}</LoadingButton>
        </Box>
    );
}

export default MaterialButton;

