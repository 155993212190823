export const statusColour = (status) => {

    if (status==='DRAFT'){
        return '#aaaaaa';
    }else if (status==='NEW'){
        return '#66bb6a';
    }
    else if (status==='ACTIVE'){
        return '#7bafdf';
    }
    else if (status==='CLOSED'){
        return '#000000';
    }
    else if (status==='ON_HOLD'){
        return '#ffa726';
    }
    else{
        return '#eeeeee';
    }
};