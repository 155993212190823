import {apiUrl, axios} from "./apiWrapper";
import {useEffect, useState} from "react";


export const useComments = (caseId, operatorCode, token) => {
    const [isLoading, setIsLoading] = useState(true);
    const [motoristComments, setMotoristComments] = useState(null);
    const [operatorComments, setOperatorComments] = useState(null);
    const [error, setError] = useState(null);

        useEffect(() => {
            let mounted = true;
            const getComments = async () => {
                if (operatorCode) {
                    return await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/comments`,
                        {headers: {'Authorization': `Bearer ${token}`}}
                    ).catch(error => {
                        setError(error.message)
                        return null;
                    });
                }
            }
            getComments().then((result) => {
                if (mounted) {
                    const operatorComments = result?.data.filter(function (comment) {
                        return comment.commentType === "OPERATOR_COMMENT"
                    });

                    const motoristComments = result?.data.filter(function (comment) {
                        return comment.commentType === "APPELLANT_COMMENT"
                    });

                    setOperatorComments(operatorComments);
                    setMotoristComments(motoristComments);
                    setIsLoading(false);
                }
            });

            return () => mounted = false;
        }, [operatorCode, caseId, token]);
    return {
        isLoading,
        error,
        operatorComments,
        motoristComments,
    };
}