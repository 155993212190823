import React from "react";
import {Paper} from "@mui/material";
import DomPurify from "dompurify";
import Typography from "@mui/material/Typography";

function PasswordResetSuccessPage() {
    return <div data-testid="login_form">
        <Paper elevation={3} className="App-Password-Reset-Form">
            <div dangerouslySetInnerHTML={{__html: DomPurify.sanitize(`<img src="/images/popla-logo-color.svg" alt="Popla logo" class="App-Popla-Logo" />`)}} />
            <div className={"App-Password-Reset-SubForm"}>
                <Typography className={"App-Password-Reset-Success-Header"}>
                    <strong>Password reset successful. Please check your mailbox for email with instructions.</strong>
                </Typography>
            </div>
        </Paper>
    </div>
}

export default PasswordResetSuccessPage;