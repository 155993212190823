import React, {useEffect, useState} from 'react';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {useUsers} from "../../api/usersApi";
import {Breadcrumbs, Link as MaterialLink, Paper, Typography, Box, Button} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import {storageType, useStorage} from "../../store";
import {tooltipCell, operatorNamesFormatter} from "../../utils/dataGridHelpers";

export const UsersView = ({dataTestId}) => {
    const rolesAllowedToCreate = ['PARENT', 'SUPERUSER'];
    const history = useHistory();
    const [pageSize, setPageSize] = useState(50);
    const [userDetails,] = useStorage(storageType.SESSION, 'userDetails', null);
    const [addUserVisible, setAddUserVisible] = useState(false);
    const {isLoading, error, users} = useUsers(sessionStorage.getItem('token'));

    useEffect(() => {
        setAddUserVisible(userDetails?.roles?.some(role => rolesAllowedToCreate.includes(role)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    const handleRowClick = (row) => {
        const userToEdit = users.users.find(u => u.id === row.id);
        history.push({
            pathname: '/users/edit',
            state: {user: userToEdit}
        });
    }

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'operatorCodes', headerName: 'Operators', flex: 1, renderCell: tooltipCell, valueGetter: params => operatorNamesFormatter(params.value, users?.operators)},
        {field: 'roles', headerName: 'Roles', flex: 1, valueFormatter: params => params.value?.toLowerCase()},
    ];

    return (
        <div style={{width: '90%', margin: '28px auto'}}>
            <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink component={Link} to="/portal" className={"App-Breadcrumb-Link"}>
                    <u>Home</u>
                </MaterialLink>
                <Typography color="textPrimary">Users</Typography>
            </Breadcrumbs>
            <div data-testid={dataTestId} style={{width: '100%'}}>
                {error
                    ?
                    error
                    :
                    <>
                        <Box display="flex" alignItems='center' justifyContent='space-between'>
                            <h1>Users</h1>
                            {addUserVisible &&
                                <Button component={Link} to="/users/new" data-testid="addUser_button">Add User</Button>
                            }
                        </Box>
                        <Paper elevation={3}>
                            <DataGridPro
                                rows={users ? users.users : []}
                                columns={columns}
                                disableSelectionOnClick
                                getRowId={(row => row.id)}
                                loading={isLoading}
                                pageSize={pageSize}
                                rowsPerPageOptions={[50,100,250,500]}
                                onPageSizeChange={setPageSize}
                                pagination
                                paginationMode="client"
                                onRowClick={handleRowClick}
                                autoHeight
                            />
                        </Paper>
                    </>
                }
            </div>
        </div>
    );
};
