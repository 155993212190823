import {useEffect, useState} from "react";
import {apiUrl, axios} from "./apiWrapper";

export const getCases = async (operatorCode, token, page, size, searchParams = [], sortValue = 'submissionDate,desc') => {
    const current = new Date()
    current.setHours(23, 59,59,999)

    const from = new Date()
    from.setHours(0,0,0,0)
    from.setMonth(from.getMonth() -6)

    const headers = {'Authorization': `Bearer ${token}`}

    const params = new URLSearchParams([
        ['page', page],
        ['size', size],
        ['fromDecisionDate', from.getTime()],
        ['toDecisionDate', current.getTime()],
        ['status', 'NEW'],
        ['status', 'ACTIVE'],
        ['status', 'CLOSED'],
        ['status', 'ON_HOLD'],
        ['sort', sortValue],
    ]);

    searchParams.forEach(param => {
        Object.entries(param).forEach(([key, value])=> {
            params.append(key, value)
        })
    })

    return await axios.get(`${apiUrl}/operators/${operatorCode}/cases?${params.toString()}`, {
        headers
    })
}

export const getAllCases = async (operatorCode, token, rows, rowLimit, searchParams, sortValue) => {
    const perPage = Math.min(rows, rowLimit);
    let pages = Math.ceil(rows / perPage);
    let requests = [];
    for (let i = 0; i < pages; i++) {
        requests.push(
            getCases(operatorCode, token, i, perPage, searchParams, sortValue)
        )
    }
    const results = await Promise.all(requests)
    return results.reduce((prev, current) => prev.concat(current.data.content), [])
}

export const useCases = (operatorCode, token, page, size, searchParams, sortValue) => {
    const [isLoading, setIsLoading] = useState(true);
    const [casesPage, setCasesPage] = useState(null);
    const [error, setError] = useState(null);
    const searchParamsString = JSON.stringify(searchParams)

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        getCases(operatorCode, token, page, size, searchParams, sortValue).then(res => {
            isMounted && setCasesPage(res.data)
        }).catch((err) => {
            isMounted && setError(err)
        }).finally(() => {
            isMounted && setIsLoading(false)
        })

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operatorCode, token, page, size, searchParamsString, sortValue]);

    return {
        isLoading,
        error,
        casesPage
    };
}

export const getCase = async (caseId, operatorCode, token) => {
    const result = await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}`,
        {headers: {'Authorization': `Bearer ${token}`}}
    )
    return result?.data;
}

export const useCase = (caseId, operatorCode, token, caseCondition) => {
    const [isLoading, setIsLoading] = useState(true);
    const [caseObject, setCaseObject] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        const getCase = async () => {
            const result = await axios.get(`${apiUrl}/operators/${operatorCode}/cases/${caseId}`,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).catch(error => {
                setError(error.message)
            });
            if (mounted) {
                setCaseObject(result ? result.data : null);
                setIsLoading(false);
            }
        }
        getCase();
        return () => {
            mounted = false;
        }
    }, [caseId, operatorCode, token, caseCondition]);

    return {
        isLoading,
        error,
        caseObject,
    };
}

export const submitCaseWithdraw = async (operatorCode, token, caseId, reasonForWithdraw, withdrawForAppellant, withdrawReasonCode) => {
    let errorStatus = {errorMessage: null};

    const withdrawnBy = withdrawForAppellant ? 'APPELLANT' : 'OPERATOR';
    const caseState = {
        withdrawnBy,
        reasonForWithdraw,
        withdrawReasonCode
    };

    const headers = {'Authorization': `Bearer ${token}`};

    await axios.post(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/withdraw`,
        caseState, { headers }
    ).catch(error => {
        if (typeof error === "string") {
            errorStatus.errorMessage = error
        } else if (error?.error) {
            errorStatus.errorMessage = error.error
        } else {
            errorStatus.errorMessage = 'Unable to transition withdrawal'
        }
    });

    return errorStatus;
}

export const updateCustomFields = async (operatorCode, token, caseId, field1, field2) => {
    let errorStatus = {errorMessage: null};
    const fieldData = {
        customField1: `${field1}`,
        customField2: `${field2}`,
    };

    const headers = {'Authorization': `Bearer ${token}`};
    await axios.post(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/custom`,
        fieldData, { headers }
    ).catch(error => {
        if (typeof error === "string") {
            errorStatus.errorMessage = error
        } else if (error?.error) {
            errorStatus.errorMessage = error.error
        } else {
            errorStatus.errorMessage = 'Unable to update custom fields'
        }
    });
    return errorStatus;
}

export const updateCaseState = async (operatorCode, caseId, state, token) => {
    const headers = {'Authorization': `Bearer ${token}`}
    return axios.post(`${apiUrl}/operators/${operatorCode}/cases/${caseId}/events`, {type: state}, {headers})
}

export const formatCasePageForUi = (casePage) => {
    return ({
        ...casePage,
        content: casePage.content.map(content => formatCaseForUi(content)),
    });
}

const formatCaseForUi = (caseObject) => {
    return ({
        ...caseObject,
        withdrawalDate: new Date(caseObject.withdrawalDate),
        decisionDate: new Date(caseObject.decisionDate),
        submissionDate: new Date(caseObject.submissionDate),
    });
}

export const getWithdrawalReasons = async (userType, token) => {
    const headers = {'Authorization': `Bearer ${token}`}
    return axios.get(`${apiUrl}/cases/withdrawalReasons?userType=` + userType, {headers}).then(res => res.data)
}

export const useWithdrawalReasons = (userType, token) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reasons, setReasons] = useState([]);
    const [refreshToken, setRefreshToken] = useState(1);

    useEffect(() => {
        let isMounted = true;
        setLoading(true)

        getWithdrawalReasons(userType, token)
            .then(data => {
                isMounted && setReasons(data)
            })
            .catch(err => {
                isMounted && setError(err)
            })
            .finally(() => {
                isMounted && setLoading(false)
            })

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, refreshToken])

    function refetch() {
        setRefreshToken(t => t + 1)
    }

    return {
        loading, error, reasons, refetch
    }
}
