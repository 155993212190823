const conditionMap = {};
conditionMap['POST_SUBMISSION'] = 'Post Submission';
conditionMap['MOTORIST_EVIDENCE_SUBMITTED'] = 'Motorist Evidence Submitted';
conditionMap['AWAITING_OPERATOR_EVIDENCE'] = 'Awaiting Operator Evidence';
conditionMap['WITHDRAWN'] = 'Withdrawn';
conditionMap['AWAITING_MOTORIST_COMMENT'] = 'Awaiting Motorist Comment';
conditionMap['WAITING_FOR_ASSESSMENT'] = 'Waiting for Assessment';
conditionMap['UNDER_REVIEW'] = 'Under Review';
conditionMap['DECISION_SENT'] = 'Decision Sent';

export const conditionMapper = (value) => {
    let condition = conditionMap[value];

    if (condition)
        return condition;
    else
        return ('Unknown Condition');
}